@import "./bootstrap";
@import url('https://fonts.googleapis.com/css?family=Work+Sans');

body {
  font-family: "Work Sans", -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol";
}

/* ---------------------------------------------- /*
 * Utility classes
/* ---------------------------------------------- */

a {
  color: #0e4f9e;
  text-decoration: none;
  &:hover {
    color: #36bfbb;
    text-decoration: none;
  }
  &:active {
    color: #329f9c;
    text-decoration: none;
  }
}

.cursor-pointer {
  cursor: pointer;
}

.list-unstyled {
  list-style: none;
}

.emoji-list {
  padding-left: 0;
}

.non-emoji-list {
  margin-left: -25px;
}

.fg-btn {
  cursor: pointer;
  color: #fff;
  display: inline-block;
  text-align: center;
  background-color: transparent;
  border: 2px solid transparent;
  min-width: 235px;
  font-weight: 600;
  font-size: 18px;
  line-height: 1.15;
  border-radius: 3px;
  padding: 10px;

  &:hover {
    color: #fff;
    box-shadow: 0 2px 12px rgba(0, 0, 0, 0.5);
  }

  &:focus {
    color: #fff;
    box-shadow: 0 0 8px #4dbeff;
  }

  &:active {
    color: #fff;
    box-shadow: 0 2px 12px rgba(0, 0, 0, 0.25);
  }

}

.fg-btn--white {
  border-color: #fff;
  background-color: #fff;
  color: #0e4f9e;

  &:hover {
    color: #0e4f9e;
    border-color: #dedede;
  }

  &:focus {
    color: #0e4f9e;
    border-color: #dedede;
    box-shadow: 0 0 8px #4dbeff;
  }

  &:active {
    color: #0e4f9e;
    background-color: #cfdcec;
    border-color: #dedede;
  }
}

.fg-btn--transparent {
  border: 2px solid #fff;
  color: #fff;

  &:hover,
  &:focus {
    background-color: #0e4f9e;
  }

  &:focus {
    border-color: #dedede
  }

  &:active {
    background-color: #3e72b1;
  }
}

.fg-btn--blue {
  background-color: #0e4f9e;
  border-color: #0e4f9e;
  color: #fff;

  &:active {
    background-color: #3e72b1;
    border-color: #3e72b1;
  }
}

.fg-btn--gradient {
  border: none;
  color: #fff;
  background: linear-gradient(143.05deg, #36BFBB -31.46%, #36BFBB 11.29%, #0E4F9E 110.21%), #FFFFFF;

  &:hover {
    background: #0e4F9e;
    box-shadow: none;
  }

  &:focus {
    background: #0e4F9e;
  }

  &:active {
    background: #3e72b1;
    box-shadow: none;
  }
}

.enable-pointer-events {
  pointer-events: all;
}

.tooltip {
  position: absolute;

  .tooltip__text {
    display: none;
    background-color: #0e2d65;
    text-align: center;
    border-radius: 4px;
    padding: 8px 24px;
    position: absolute;
    width: 100px;
    margin-left: -15px;
  }

  .tooltip__text::after {
    content: "";
    position: absolute;
    left: 50%;
    margin-left: -8px;
    border-width: 8px;
    border-style: solid;
  }

  &-bottom {
    top: 200%;
    margin-top: 12px;

    .tooltip__text {
      display: inline;

      &::after {
        bottom: 100%;
        border-color: transparent transparent #0e2d65 transparent;
      }
    }
  }

  &-top {
    bottom: 100%;
    margin-bottom: 12px;

    .tooltip__text {
      display: inline;
      bottom: 250%;

      &::after {
        top: 100%;
        border-color: #0e2d65 transparent transparent transparent;
      }
    }
  }
}

/* ---------------------------------------------- /*
 * Mixins and custom animations
/* ---------------------------------------------- */

@keyframes textBlink {
  0% {
    opacity: 0.1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

@keyframes shake {
  0% { transform: rotateZ(0deg); }
  50% { transform: rotateZ(7deg); }
  95% { transform: rotateZ(-2deg); }
  100% { transform: rotateZ(0deg); }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@mixin text-blink {
  animation: textBlink 0.4s linear;
}

$ecosystem-anim-duration : 0.2s;
$ecosystem-anim-delay: 0.5s;
$ecosystem-anim-total-duration : $ecosystem-anim-delay + $ecosystem-anim-duration;
@function calculate-delay($iteration) {
  @return $ecosystem-anim-delay + ($iteration * $ecosystem-anim-total-duration);
}

@mixin shake-animation($delay1, $delay2, $delay3) {
  animation-name: shake, shake, shake;
  animation-delay: $delay1, $delay2, $delay3;
  animation-duration: $ecosystem-anim-duration;
  animation-timing-function: ease-out;
}

@mixin stroke-fade-in {
  animation: fadeIn 0.2s ease-in-out;
}

/* ---------------------------------------------- /*
 * Title section
/* ---------------------------------------------- */

.fg-section-title {
  font-weight: 600;
  font-size: 32px;
  line-height: 1.18;
  color: #0e4f9e;
}

.fg-section-subtitle {
  font-size: 16px;
  line-height: 1.6;
  color: #212121;
  margin-top: 24px;
}

.fg-head-container {
  position: relative;
  overflow: hidden;
  height: 70vh;
  margin-top: 0;
  transition: margin-top 0.1s linear;
  background: rgba(14, 79, 158, 0.8); /* Fallback for Edge where the video does not fill completely*/
}

.bg-head-section {
  position: absolute;
  height: 100%;
  object-fit: cover;
}

.overlay {
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: #0e4f9e;
  opacity: 0.75;
  z-index: 1;
}

.fg-head-section {
  height: 100%;
  position: relative;
  z-index: 1;
}

.fg-navbar__link {
  color: #ffffff;
}

.nav-sticky {
  .fg-nav-section {
    position: fixed;
    background-color: #ffffff;
    box-shadow: 0 2px 2px #e5e5e5;
    width: 100%;
    z-index: 99;
  }

  .fg-navbar__link {
    font-weight: 600;
    color: #0E4F9E;

    &:hover {
      color: #36bfbb;
    }

    &:active {
      color: #329f9c;
    }
  }

  .fg-navbar__try-now {
    display: inline-block;
  }
}

.fg-nav-container {
  position: absolute;
  top: 0;
  z-index: 2;
}

.fg-navbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px;
}

.fg-navbar__link,
.fg-navbar__try-now {
  margin-left: 32px;
}

.fg-navbar__try-now {
  display: none;
  min-width: 136px;
}

.fg-navbar__flynn {
  height: 40px;
}

.fg-title-section {
  color: #ffffff;
  align-items: center;
  height: 85%
}

.fg-title-section__subtitle {
  margin-top: 35px;
  font-size: 24px;
  line-height: 1.16;
}

/* ---------------------------------------------- /*
 * Try flogo section
/* ---------------------------------------------- */

.fg-try-flogo {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
}

.fg-try-flogo__bg {
  position: absolute;
  width: 100%;
  height: 200px;
  background: linear-gradient(152.77deg, #36bfbb 34.61%, #0e4f9e 139.86%),
    #ffffff;
  transform: skewY(3deg);
  backface-visibility: hidden;
}

.fg-try-flogo__btns {
  position: relative;
  justify-content: center;
}

.fg-try-flogo__try-flogo {
  margin-right: 15px;
}

.fg-try-flogo__go-to-source {
  margin-left: 15px;
}

/* ---------------------------------------------- /*
 * Ecosystem section
/* ---------------------------------------------- */

.fg-main-section {
  background-image: url("/assets/images/content-background.png");
  background-repeat: no-repeat;
  background-size: cover;
}

.fg-ecosystem {
  padding-top: 130px;
  align-items: center;
}

.fg-ecosystem__abt-resource {
  background-color: #ffffff;
  border: 1px solid #dedede;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  padding: 25px;
  min-height: 450px;
  display: none;

  &.active {
    display: block;

    .text-blink {
      @include text-blink;
    }
  }
}

.fg-ecosystem__abt-resource-header {
  display: inline-block;
  border-radius: 5px;
  padding: 12px 10px;
}

.fg-ecosystem__abt-resource-header--bg-dark-green {
  background-color: #36bfbb80;
}

.fg-ecosystem__abt-resource-header--bg-blue {
  background-color: #d6f4f7;
}

.fg-ecosystem__abt-resource-header--bg-violet {
  background-color: #eaedfe;
}

.fg-ecosystem__abt-resource-header--bg-red {
  background-color: #fbd9e5;
}

.fg-ecosystem__abt-resource-header--bg-green {
  background-color: #8ce5bb80;
}

.fg-ecosystem__abt-resource-header--bg-yellow {
  background-color: #fef0d6;
}

.fg-ecosystem__resources {
  position: relative;
  padding: 0;
}

.fg-ecosystem__help-text-block {
  position: absolute;
  right: 0;
  bottom: 48%;
  left: 54%;
  opacity: 1;

  &.fadeOut {
    opacity: 0;
    transition: opacity 0.2s linear;
  }
}

.fg-ecosystem__help-text {
  display: inline-block;
  color: #36BFBB;
  font-size: 18px;
  font-weight: 600;
  line-height: 21px;
  width: 200px;
  position: relative;
  bottom: 10px;
  right: 40px;
}

.fg-ecosystem__resources-block {
  position: absolute;
  pointer-events: none;
  user-select: none;
  width: 100%;
  left: 0;
  z-index: 1;
}

.animate-ecosystem {
  .fg-ecosystem__animate-rules {
    @include shake-animation(calculate-delay(0), calculate-delay(3), calculate-delay(6));
    transform-origin: 37% 59%;
  }

  .fg-ecosystem__animate-micro-gateway {
    @include shake-animation(calculate-delay(1), calculate-delay(4), calculate-delay(7));
    transform-origin: 37% 71%;
  }

  .fg-ecosystem__animate-streams {
    @include shake-animation(calculate-delay(2), calculate-delay(5), calculate-delay(8));
    transform-origin: 51% 72%;
  }
}

.animate-ecosystem-none {
  .fg-ecosystem__animate-rules,
  .fg-ecosystem__animate-streams,
  .fg-ecosystem__animate-micro-gateway {
    animation: none;
  }
}

.toggle-opacity {
  opacity: 0;
}

.bg-flynn {
  transform: translate(85px, 9px);
}

@mixin resource-selected {
  opacity: 1;
  fill: url(#ecosystem-fill-gradient);
}

.active-resource-core {
  .ecosystem-fill-color {
    stop-color: #36BFBB;
  }

  .res-bg-core {
    @include resource-selected;
  }
}

.active-resource-machinelearning {
  .ecosystem-fill-color {
    stop-color: #FCB533;
  }

  .res-bg-ml {
    @include resource-selected;
  }
}

.active-resource-flows {
  .ecosystem-fill-color {
    stop-color: #33C6D8;
  }

  .res-bg-flows {
    @include resource-selected;
  }
}

.active-resource-streams {
  .ecosystem-fill-color {
    stop-color: #96A7F8;
  }

  .res-bg-streams {
    @include resource-selected;
  }
}

.active-resource-rules {
  .ecosystem-fill-color {
    stop-color: #EA437B;
  }

  .res-bg-rules {
    @include resource-selected;
  }
}

.active-resource-microgateway {
  .ecosystem-fill-color {
    stop-color: #8CE5BB;
  }

  .res-bg-micro {
    @include resource-selected;
  }
}

.fg-ecosystem__resource-fill {
  position: absolute;
  left: 0;
}

.micro-outline, .rules-outline, .streams-outline, .flows-outline, .core-outline, .ml-outline {
  fill:none;
  stroke-miterlimit:10;
  stroke-width:6px;
}

.res-outline-block:hover {
  .micro-outline {
    stroke:#8de6ba;
    @include stroke-fade-in;
  }

  .rules-outline {
    stroke:#ea437b;
    @include stroke-fade-in;
  }

  .streams-outline {
    stroke:#96a7f8;
    @include stroke-fade-in;
  }

  .flows-outline {
    stroke:#33c6d8;
    @include stroke-fade-in;
  }

  .core-outline {
    stroke:#36bfbb;
    @include stroke-fade-in;
  }

  .ml-outline {
    stroke:#fcb533;
    @include stroke-fade-in;
  }
}

/* ---------------------------------------------- /*
 * Usecase section
/* ---------------------------------------------- */

.fg-usecase-section {
  padding: 104px 0;
}

.fg-usecase {
  padding: 24px;
  background-color: #ffffff;
  border: 1px solid #dedede;
  border-radius: 6px;
  height: 100%;
}

.fg-usecase-img {
  height: 216px;
  text-align: center;
  max-width: 100%;
  margin: 0 auto;
  display: block;
}

.fg-usecase-desc {
  color: #727272;
}

/* ---------------------------------------------- /*
 * Why flogo section
/* ---------------------------------------------- */

.fg-showcase-img {
  max-width: 100%;
}

.fg-showcase-btn {
  min-width: 282px;
  margin-top: 20px;
  padding: 10px 20px;
  display: inline-block;
}

/* ---------------------------------------------- /*
 * Get started section
/* ---------------------------------------------- */

.fg-get-start-section {
  background-color: rgba(14, 79, 158, 0.05);
}

.fg-get-start {
  padding: 80px 0;
}

.fg-get-start__tabs-block {
  height: 220px;
  background-color: #19417F;
  color: #ffffff;
  border-radius: 5px;
  position: relative;
}

.tabs {
  display: flex;
}

.tabs__tab {
  flex: 1;
  background-color: #0e4f9e;
  padding: 10px 20px;

  &:hover {
    .tab-label {
      opacity: 0.75;
    }
  }
}

.tabs__tab:first-child {
  border-top-left-radius: 5px;
}

.tabs__tab:last-child {
  border-top-right-radius: 5px;
}

.tabs__tab:not(:last-child) {
  cursor: pointer;
}

.tabs__tab--active {
  background-color: #19417F;

  &:hover {
    .tab-label {
      opacity: 1;
    }
  }
}

.fg-get-start__command {
  padding: 20px;
  font-size: 16px;
  font-family: monospace;
  display: none;
}

.fg-get-start__command--open {
  display: block;
}

.fg-get-start__command-line::before {
  content: "$";
  margin-right: 1em;
}

.copy-command {
  position: absolute;
  right: 24px;
  bottom: 24px;
}

.copy-command__btn {
  background: transparent;
  border: none;
  color: #ffffff;
  font-weight: 600;

  &::before {
    position: relative;
    top: 4px;
    right: 8px;
    content: url("../../assets/images/ic_copy.svg");
  }

  &:hover {
    opacity: 0.75;
  }
}

/* ---------------------------------------------- /*
 * Footer
/* ---------------------------------------------- */

.fg-footer {
  margin: 0;
  background-color: #0e4f9e;
  color: #ffffff;
  align-items: center;
  font-size: 12px;
}

.fg-footer__links__link {
  color: #ffffff;
  margin: 0 6px;
}

.menu-icon {
  color: #fff;
}

/* ---------------------------------------------- /*
 * Media (Responsive)
/* ---------------------------------------------- */

@media (max-width: 575px) {
  .fg-showcase-btn {
    width: 100%;
  }

  .tabs__tab {
    padding: 10px 0 10px 8px;
  }

  .fg-get-start__command {
    padding: 10px 0 10px 8px;
  }
}

@media (max-width: 767px) {
  .container {
    max-width: 100%;
  }

  .fg-head-container {
    height: 500px;
  }

  .fg-navbar {
    display: block;
    background-color: transparent;
  }

  .fg-navbar__logo {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .fg-navbar__links {
    text-align: center;
    overflow: hidden;
    max-height: 0;
    transition: max-height 0.5s linear;
  }

  .fg-navbar__link {
    display: block;
    color: #0E4F9E;
    font-weight: 600;
    padding: 12px 0;
    margin-left: 0;
  }

  .fg-navbar__flynn {
    height: 24px;
  }

  .fg-navbar__try-now {
    display: none;
  }

  .--open {
    .menu-icon {
      color: #0e4f9e;
    }

    .fg-navbar {
      background-color: #ffffff;
    }

    .fg-navbar__links {
      max-height: 150px;
    }
  }

  .fg-try-flogo {
    z-index: 1;
  }

  .fg-try-flogo__btns {
   flex-direction: column;
  }

  .fg-try-flogo__try-flogo {
    margin-right: 0;
  }

  .fg-try-flogo__go-to-source {
    margin-top: 40px;
    margin-left: 0;
  }

  .toggle-opacity {
    opacity: 1;
  }

  /* !important is used to fill the ecosystem blocks bg color
      always unlike desktop irrespective of user selection
  */

  .res-bg-core {
    fill: #36BFBB !important;
  }

  .res-bg-ml {
    fill: #FCB533 !important;
  }

  .res-bg-flows {
    fill: #33C6D8 !important;
  }

  .res-bg-streams {
    fill: #96A7F8 !important;
  }

  .res-bg-rules {
    fill: #EA437B !important;
  }

  .res-bg-micro {
    fill: #8CE5BB !important;
  }

  .fg-ecosystem__abt-resource-section {
    display: flex;
    flex-wrap: nowrap;
    transition: transform 0.3s linear;
  }

  .fg-ecosystem__abt-resource {
    display: block;
    min-width: 100%;
    border: none;
    box-shadow: none;

    &.active {
      border: 1px solid #dedede;
      box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    }
  }

  .animate-ecosystem {
    .fg-ecosystem__animate-rules,
    .fg-ecosystem__animate-streams,
    .fg-ecosystem__animate-micro-gateway {
      animation: none;
    }
  }

  .slider-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    z-index: 1;
  }

  .slider-btn--prev {
    left: 0;
  }

  .slider-btn--next {
    top: 0;
    right: 0;
  }

  .slider-btn__arrow {
    border-radius: 50%;
    border: 1px solid #c4c4c4;
    background: #c4c4c4;
  }

  .slider-btn__arrow-img {
    margin-bottom: 3px;
  }

  .slider-btn__arrow-img--left {
    transform: rotate(180deg);
  }

  .active-resource {
    &-first .slider-btn--prev {
      visibility: hidden;
    }

    &-last .slider-btn--next {
      visibility: hidden;
    }
  }

  .slider-indicators {
    list-style: none;
    margin-top: 36px;
    display: flex;
    justify-content: center;
    padding-left: 0;
  }

  .slider-indicators__indicator {
    height: 16px;
    width: 16px;
    border: 2px solid #36BFBB;
    border-radius: 50%;
    transition: background-color 0.3s;

    &.active {
      background-color: #36BFBB;
    }
  }

  .overflow-x-auto {
    overflow-x: auto;
  }

  .flex-no-wrap {
    flex-wrap: nowrap;
  }

  .fg-usecase {
    width: 270px;
  }

  .fg-title-section__title {
    max-width: 300px;
  }

  .fg-section-title {
    font-size: 24px;
  }

  .fg-section-subtitle {
    font-size: 14px;
    margin-top: 16px;
  }

  .fg-title-section__subtitle {
    margin-top: 25px;
    font-size: 16px;
    line-height: 1.6;
  }

  .fg-try-flogo__bg {
    height: 300px;
  }

  .fg-footer {
    font-size: 9px;
  }
}

@media (max-width: 999px) {
  .fg-main-section {
    background-image: none;
  }
}

@media only screen and (max-width: 999px) and (orientation: landscape) {
  .fg-head-container {
    height: 100vh;
  }
}
